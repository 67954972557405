<template>
    <modal-page-template title="Inscription">
        <b-col lg="8" offset-lg="2">
            <b-form @submit.prevent="verifyCaptcha">
                <b-form-group label="Prénom">
                    <b-form-input v-model="form.prenom" type="text" required/>
                </b-form-group>

                <b-form-group label="Nom">
                    <b-form-input v-model="form.nom" type="text" required/>
                </b-form-group>

                <b-form-group>
                    <label v-b-tooltip.hover="'Idéalement une adresse INSA si vous en avez une'"
                           slot="label">
                        Adresse mail
                        <font-awesome-icon icon="question-circle"/>
                    </label>
                    <b-form-input v-model="form.email" type="email" required/>
                </b-form-group>

                <b-form-group>
                    <label v-b-tooltip.hover="'Votre mot de passe doit faire au moins 8 caractères'" slot="label">
                        Mot de passe
                        <font-awesome-icon icon="question-circle"/>
                    </label>
                    <b-form-input v-model="form.password" type="password" required autocomplete="new-password"/>
                </b-form-group>

                <b-form-group label="Confirmation">
                    <b-form-input v-model="form.passwordConf" type="password" required autocomplete="new-password"/>
                </b-form-group>

                <b-form-group>
                    <label v-b-tooltip.hover="'Si vous sélectionnez une ou plusieurs associations, votre compte devra être validé par un membre du bureau du Cluji avant d\'être pleinement fonctionnel'"
                           slot="label">
                        Association(s)
                        <font-awesome-icon icon="question-circle"/>
                    </label>
                    <multi-select v-model="form.associations"
                                  :options="sortedAssos"
                                  multiple="true"
                                  :close-on-select="false"
                                  track-by="id"
                                  :clear-on-select="false"
                                  :preserve-search="false"
                                  label="nom"/>
                </b-form-group>

                <b-form-group>
                    <label v-b-tooltip.hover="'Si votre association ne se trouve pas encore dans la liste, ajoutez-la ici'"
                           slot="label">
                        Ajouter une association
                        <font-awesome-icon icon="question-circle"/>
                    </label>
                    <b-input-group>
                        <b-form-input v-model="form.otherAsso" type="text" v-on:keydown.enter.prevent='addAsso'/>

                        <b-button variant="success" @click="addAsso" slot="append">
                            <font-awesome-icon icon="plus"/>
                        </b-button>
                    </b-input-group>
                </b-form-group>

                <b-form-checkbox id="checkbox-1" v-model="form.CGU" :value="true"
                                 name="CGU" required :unchecked-value="false">
                    J'ai lu et j'accepte les
                    <router-link :to="{name: 'cgu'}" target='_blank'>
                        conditions générales d'utilisation
                        <font-awesome-icon icon="external-link-alt"/>
                    </router-link>
                </b-form-checkbox>

                <vue-recaptcha ref="recaptcha" @verify="submit" size="invisible" :sitekey="siteKey"
                               :loadRecaptchaScript="true"/>

                <b-btn type="submit" variant="secondary" class="m-3" :disabled="loading">
                    <font-awesome-icon icon="user-plus"/>
                    Inscription
                    <font-awesome-icon icon="sync" spin v-if="loading"/>
                </b-btn>
            </b-form>
        </b-col>
    </modal-page-template>
</template>

<script>
    import {apiPath} from "@/util/http";

    const ModalPageTemplate = () => import('@/components/templates/ModalPageTemplate');
    const MultiSelect       = () => import('@/components/MultiSelect');
    const VueRecaptcha      = () => import('vue-recaptcha');

    import assoSelectorMixin from '@/mixin/assoSelectorMixin';

    export default {
        name: "SignUp",
        mixins: [assoSelectorMixin],
        components: {MultiSelect, ModalPageTemplate, VueRecaptcha},
        data: () => ({
            loading: false,
            siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
            redirectTo: null,
            form: {
                prenom: null,
                nom: null,
                email: null,
                password: null,
                passwordConf: null,
                CGU: false
            },
        }),
        methods: {
            verifyCaptcha() {
                if (!this.loading) {
                    this.loading = true;
                    this.$refs.recaptcha.execute();
                }
            },
            submit(token) {
                this.axios.post(apiPath('sign_up'), {...this.form, captchaToken: token})
                    .then(() => {
                        this.$toaster.success("Un mail vous a été envoyé pour finaliser votre inscription (pensez à vérifier vos spams)");
                        this.$router.push(this.redirectTo || {name: 'dashboard_home'});
                    })
                    .catch(error => this.$toaster.error(error.response.data.error || 'Erreur inconnue'))
                    .finally(() => this.loading = false);
            }
        },
        mounted() {
            this.redirectTo = this.$route.query.redirectTo || null;
        }
    }
</script>
